import React from 'react'
import {
  CFImage,
  CFLink,
  CFSelect,
  CFView,
  DefaultScreen,
  MobileScreen,
} from 'components'
import {
  locationMapCoquitlam,
  mobileMapCoquitlam,
  locationMapRichmond,
  mobileMapRichmond,
} from 'images'
import { dispatch } from 'store'

export default () => {
  return (
    <CFSelect selector={dispatch.restaurant.getSelectedLocationId}>
      {locationId => {
        let locationLink = ''
        let mapImageMobile = null
        let mapImage = null
        if (locationId === '1PospYGYy8tUYqotdGyH') {
          locationLink = 'https://g.page/oz-chicken?share'
          mapImage = locationMapCoquitlam
          mapImageMobile = mobileMapCoquitlam
        } else {
          locationLink = 'https://goo.gl/maps/qJvrF8WTSx45GP999'
          mapImage = locationMapRichmond
          mapImageMobile = mobileMapRichmond
        }
        return (
          <CFView>
            <MobileScreen>
              <CFLink href={locationLink}>
                <CFImage
                  src={mapImageMobile}
                  w="100%"
                  alt="Oz Korean Restaurant Map"
                />
              </CFLink>
            </MobileScreen>
            <DefaultScreen>
              <CFLink href={locationLink}>
                <CFImage
                  src={mapImage}
                  w="100%"
                  alt="Oz Korean Restaurant Map"
                />
              </CFLink>
            </DefaultScreen>
          </CFView>
        )
      }}
    </CFSelect>
  )
}
