import React from 'react'
import { CFView, CFImage, DefaultScreen, MobileScreen } from 'components'
import { foodGallery, mobileGallery } from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView column center>
          <CFImage src={mobileGallery} w="100%" alt="Food Gallery" />
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView column center maxWidth="1400px" m="0 auto" mt="-1px">
          <CFImage src={foodGallery} w="100%" alt="Food Gallery" m="0 auto" />
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
